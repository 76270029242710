export default {
  GET(state, data) {
    state.notification = { ...data }
  },
  LIST(state, data) {
    state.notifications = data
  },
  SET_FORM(state, data) {
    state.notificationForm = { ...data }
  },
}
