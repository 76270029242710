import {
  SETTING_USERS_DEFAULTS,
  SETTING_USERS_SYSTEM_USERS,
  SETTING_USERS_ROLES, SETTING_USERS_NOTIFICATIONS,
} from '../meta/settings-sub-menu/users'

// eslint-disable-next-line import/prefer-default-export
export default function userMenuItems(active, params = {}) {
  const menuItems = [
    {
      name: 'settings-users-defaults',
      layout: 'Default',
      ...SETTING_USERS_DEFAULTS,
    },
    {
      name: 'user-list',
      layout: 'System Users',
      ...SETTING_USERS_SYSTEM_USERS,
    },
    {
      name: 'settings-users-roles',
      layout: 'Roles',
      ...SETTING_USERS_ROLES,
    },
    {
      name: 'settings-users-notifications',
      layout: 'Notifications',
      ...SETTING_USERS_NOTIFICATIONS,
    },
  ]
  menuItems.forEach(item => {
    // eslint-disable-next-line no-param-reassign
    item.active = item.name === active
    // eslint-disable-next-line no-param-reassign
    item.route = { name: item.name, params }
  })
  return {
    menuItems,
  }
}
