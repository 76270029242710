// eslint-disable-next-line import/prefer-default-export
export const SETTING_USERS_SYSTEM_USERS = {
  action: 'view',
  resource: 'System Users',
}
export const SETTING_USERS_ROLES = {
  action: 'view',
  resource: 'Roles',
}
export const SETTING_USERS_NOTIFICATIONS = {
  action: 'view',
  resource: 'Notifications',
}
export const SETTING_USERS_DEFAULTS = {
  action: 'view',
  resource: 'Defaults',
}
