import crudActions from '@/libs/storeConstActions'
import axios from '@/libs/axios'

const endpoint = 'settings/notification'
const Actions = crudActions(endpoint)
const duplicate = (ctx, data) => axios.get(`${endpoint}/${data.id}/duplicate`)

export default {
  ...Actions,
  duplicate,
}
