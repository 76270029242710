<template>
  <router-view />
</template>

<script>
import { onUnmounted } from '@vue/composition-api'
import userMenuItems from '@/views/components/sub-menu/settings-sub-menu/users'
import store from '@/store'
import notificationsModule from '@/store/user/user-notifications'

export default {
  name: 'Notifications',
  created() {
    this.$emit('updateMenu', 'settings-users-notifications')
  },
  setup() {
    const { menuItems } = userMenuItems('settings-users-notifications')
    const MODULE_NAME = 'notification'
    if (!store.hasModule(MODULE_NAME)) store.registerModule(MODULE_NAME, notificationsModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MODULE_NAME)) store.unregisterModule(MODULE_NAME)
    })
    return {
      menuItems,
    }
  },
}
</script>

<style scoped>

</style>
